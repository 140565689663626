import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { settings } from 'ionicons/icons';
import { useLocation } from 'react-router-dom';
import { firebaseAuth } from '../../firebase';
import {
  commonAppPages,
  loggedInAppPages,
  loggedOutAppPages,
  userAppPages,
} from './contants';
import './Menu.css';
import { AppPage } from './types';
import { useAuthState } from 'react-firehooks/auth';

const Menu: React.FC = () => {
  const location = useLocation();
  const [user] = useAuthState(firebaseAuth);

  const pages = [
    ...commonAppPages,
    ...(user ? loggedInAppPages : loggedOutAppPages),
  ];

  const userPages: AppPage[] = user ? userAppPages : [];

  const mapPage = (appPage: AppPage) => {
    return (
      <IonMenuToggle key={appPage.url} autoHide={false}>
        <IonItem
          className={location.pathname === appPage.url ? 'selected' : ''}
          routerLink={appPage.url}
          routerDirection="none"
          lines="none"
          detail={false}
        >
          <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
          <IonLabel>{appPage.title}</IonLabel>
        </IonItem>
      </IonMenuToggle>
    );
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Cloud Soundboard</IonListHeader>
          <IonNote></IonNote>
          {pages.map(mapPage)}
        </IonList>

        <IonList id="labels-list">{userPages.map(mapPage)}</IonList>
      </IonContent>
      {user ? (
        <IonFooter>
          <IonToolbar>
            <IonButton fill="clear" expand="full" color="primary">
              <span className="marginRight">Settings</span>
              <IonIcon slot="end" icon={settings} />
            </IonButton>
          </IonToolbar>
        </IonFooter>
      ) : null}
    </IonMenu>
  );
};

export default Menu;

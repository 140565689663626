import { IonRouterOutlet } from '@ionic/react';
import { getAuth } from 'firebase/auth';
import { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthState } from 'react-firehooks/auth';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import {
  GuardFunctionRouteProps,
  GuardToRoute,
  Next,
} from 'react-router-guards/dist/types';
import { firebaseAuth } from './firebase';
import Home from './pages/Home/Home';
import Friends from './pages/Friends/Friends';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Soundboard from './pages/Soundboard/Soundboard';
import SoundClips from './pages/SoundClips/SoundClips';

const Routes: React.FC = () => {
  const [_user, loading] = useAuthState(getAuth());
  const loadingRef = useRef(true);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const requireLogin = async (
    to: GuardToRoute,
    from: GuardFunctionRouteProps | null,
    next: Next
  ) => {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!loadingRef.current) {
          clearInterval(interval);
          resolve();
        }
      });
    });

    if (to.meta.auth && !firebaseAuth.currentUser) {
      next.redirect('/page/Login');
    } else if (to.meta.notLoggedIn && firebaseAuth.currentUser) {
      next.redirect('/page/Home');
    } else {
      next();
    }
  };

  // TODO error={NotFound}
  return (
    <IonRouterOutlet id="main">
      <GuardProvider guards={[requireLogin]}>
        <GuardedRoute path="/" exact={true}>
          <Redirect to="/page/Home" />
        </GuardedRoute>
        <GuardedRoute
          path="/page/Login"
          exact={true}
          meta={{ notLoggedIn: true }}
        >
          <Login />
        </GuardedRoute>
        <GuardedRoute path="/page/Home" exact={true}>
          <Home />
        </GuardedRoute>
        <GuardedRoute path="/page/SoundClips" exact={true}>
          <SoundClips />
        </GuardedRoute>
        <GuardedRoute path="/page/Profile" exact={true} meta={{ auth: true }}>
          <Profile />
        </GuardedRoute>
        <GuardedRoute path="/page/Friends" exact={true} meta={{ auth: true }}>
          <Friends />
        </GuardedRoute>
        <GuardedRoute path="/page/Soundboard" meta={{ auth: true }}>
          <Soundboard />
        </GuardedRoute>
      </GuardProvider>
    </IonRouterOutlet>
  );
};

export default Routes;

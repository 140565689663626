import { IonPage } from '@ionic/react';
import Header from 'components/Header/Header';

const Friends: React.FC = () => {
  return (
    <IonPage>
      <Header name="Friends" />
    </IonPage>
  );
};

export default Friends;

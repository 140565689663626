import { SOUND_CLIP_COLLECTION } from "@cloud-soundboard/shared";
import { SegmentChangeEventDetail } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from "@ionic/react";
import ConfigureSoundClip from "components/ConfigureSoundClip/ConfigureSoundClip";
import FilterPopover from "components/FilterPopover/FilterPopover";
import IonInstantSearchBar from "components/IonInstantSearchBar/IonInstantSearchBar";
import { getAuth } from "firebase/auth";
import {
  addSharp,
  flameSharp,
  flashSharp,
  heartSharp,
  optionsOutline,
} from "ionicons/icons";
import { useState } from "react";
import { useAuthState } from "react-firehooks/auth";
import { InstantSearch, Stats } from "react-instantsearch-dom";
import { MeiliSearchClient } from "../../model/meilisearch";
import SoundClipItems from "./SoundClipItems";
import "./style.css";
import { SearchType } from "./types";

// TODO
// const sessionStorageCache = createInfiniteHitsSessionStorageCache();

const SoundClips: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [user] = useAuthState(getAuth());
  const [searchType, setSearchType] = useState<SearchType>("new");
  const [event, setFilterEvent] = useState<any>();
  const [ratingState, setRatingState] = useState<any>();
  const onSegmentChanged = (e: CustomEvent<SegmentChangeEventDetail>) => {
    setSearchType(e.detail.value as SearchType);
  };

  return (
    <IonPage>
      <InstantSearch
        onSearchStateChange={(state) => {
          if (event && state?.range?.avgRating) {
            setRatingState(state.range?.avgRating);
          }
        }}
        indexName={SOUND_CLIP_COLLECTION}
        searchClient={MeiliSearchClient}
      >
        <FilterPopover
          ratingState={ratingState}
          onDidDismiss={() => setFilterEvent(undefined)}
          event={event}
        />
        <IonHeader className="header">
          <IonToolbar>
            <IonButtons
              style={{
                background: "white",
              }}
              slot="start"
            >
              <IonMenuButton />
            </IonButtons>
            <IonInstantSearchBar />
            <IonButtons slot="end">
              <IonButton onClick={setFilterEvent}>
                <IonIcon icon={optionsOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="main">
          <ConfigureSoundClip
            close={() => setShowModal(false)}
            isOpen={showModal}
            soundClip={undefined}
          />

          <IonSegment onIonChange={onSegmentChanged} value={searchType}>
            <IonSegmentButton
              className="segment-button"
              layout="icon-start"
              value="new"
            >
              <IonIcon icon={flashSharp} />
              <IonLabel>New</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              className="segment-button"
              layout="icon-start"
              value="trending"
            >
              <IonIcon icon={flameSharp} />
              <IonLabel>Trending</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              className="segment-button"
              layout="icon-start"
              value="favorites"
            >
              <IonIcon icon={heartSharp} />
              <IonLabel>Favorites</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <SoundClipItems />
          {user ? (
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton onClick={() => setShowModal(true)}>
                <IonIcon icon={addSharp} />
              </IonFabButton>
            </IonFab>
          ) : null}
          <Stats />
        </IonContent>
      </InstantSearch>
    </IonPage>
  );
};

export default SoundClips;

import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { AccordionProps } from "./types";
import "./style.css";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowDown, arrowUp, caretDown, caretUp } from "ionicons/icons";
const Accordion: React.FC<AccordionProps> = ({ children, title }) => {
  const [expanded, setExpanded] = useState(false);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <IonListHeader onClick={() => setExpanded(!expanded)}>
        <IonLabel>{title}</IonLabel>
        <IonIcon
          style={{ marginRight: 5 }}
          icon={expanded ? caretUp : caretDown}
        ></IonIcon>
      </IonListHeader>
      <AnimatePresence presenceAffectsLayout initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div style={{ transformOrigin: "top center" }}>{children}</div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default Accordion;

import { Soundboard, SOUNDBOARD_COLLECTION } from '@cloud-soundboard/shared';
import { firebaseAuth, firestoreApp } from '../firebase';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useCollection } from 'react-firehooks/firestore';
import { User } from '@firebase/auth';
import { nanoid } from 'nanoid';

const DEFAULT_PADDING = 10;

export const TEMP_ITEM = 'temp-item';
const soundboardConverter = {
  toFirestore(soundboard: Soundboard): DocumentData {
    return soundboard;
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Soundboard {
    const data = snapshot.data(options);
    return data as Soundboard;
  },
};

export const useUsersGridCollection = () => {
  const [user] = useAuthState(firebaseAuth);
  const [gridCollection, setGridCollection] =
    useState<CollectionReference<Soundboard>>();

  useEffect(() => {
    if (user) {
      const userCollection = getUserCollection(user);
      setGridCollection(userCollection);
    } else {
      setGridCollection(undefined);
    }
  }, [user]);

  const [value, loading, error] = useCollection(gridCollection);
  return { collection: gridCollection, value, loading, error };
};

function getUserCollection(user: User) {
  return collection(
    firestoreApp,
    SOUNDBOARD_COLLECTION,
    'user',
    user?.uid ?? ''
  ).withConverter(soundboardConverter);
}

/** Saves a soundboard object. If no soundboard is passed a new one will be created with defaults. */
export const saveSoundboard = (
  collection: CollectionReference<Soundboard>,
  partialSoundboard?: Partial<Soundboard>
) => {
  if (!collection) {
    return;
  }
  const id = partialSoundboard?.id ?? nanoid();
  const newSoundboard: Soundboard = {
    id,
    cols: 5,
    rows: 5,
    itemSize: 90,
    items: [],
    name: 'New Soundboard',
    position: 0,
    padding: DEFAULT_PADDING,
    ...(partialSoundboard ?? {}),
  };

  newSoundboard.items = newSoundboard.items
    .filter(Boolean)
    .filter((item) => item.id.includes(TEMP_ITEM));
  console.log('saving');
 //return setDoc<Soundboard>(doc<Soundboard>(collection, id), newSoundboard);
};

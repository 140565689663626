import { Soundboard } from '@cloud-soundboard/shared';
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonItem,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { createRef, useEffect, useState } from 'react';
import Grid from 'components/Grid/Grid';
import Header from 'components/Header/Header';
import { saveSoundboard, useUsersGridCollection } from '../../firebase';
import SoundboardSettings from './SoundboardSettings';
import './style.css';
import { Layout } from 'react-grid-layout';
import { debounce } from 'lodash';

const SoundboardPage: React.FC = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = createRef<HTMLDivElement>();
  const [currentSoundboard, setCurrentSoundboard] = useState<Soundboard>();
  const [soundboards, setSoundboards] = useState<Soundboard[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const currentHeight = ref.current?.getBoundingClientRect().height ?? 0;
  const currentWidth = ref.current?.getBoundingClientRect().width ?? 0;

  useEffect(() => {
    setHeight(ref.current?.getBoundingClientRect().height ?? 0);
    setWidth(ref.current?.getBoundingClientRect().width ?? 0);
  }, [ref, currentWidth, currentHeight]);

  const { collection, value, loading } = useUsersGridCollection();

  useEffect(() => {
    if (loading || !value) {
      return;
    }
    if (value.docs.length > 0) {
      setSoundboards(value.docs.map((snap) => snap.data()));
      setCurrentSoundboard(value.docs[0].data());
    } else if (collection) {
      saveSoundboard(collection);
    }
  }, [collection, loading, value]);

  // Wait till we are done loading.
  if (!collection || loading) {
    return null;
  }

  const setSoundboard = (id: string) => {
    setCurrentSoundboard(
      soundboards.find((soundboard) => soundboard.id === id)
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const updateSettings = async (soundBoard: Soundboard) => {
    closeModal();
    await saveSoundboard(collection, soundBoard);
  };

  const renderSelect = () => {
    return (
      <IonButtons slot="end">
        <IonItem>
          <IonSelect
            compareWith={(prev, next) => {
              return prev.id === next.id && prev.name === next.name;
            }}
            value={currentSoundboard?.id}
            onIonChange={(e) => setSoundboard(e.detail.value)}
          >
            {soundboards.map((soundboard) => (
              <IonSelectOption
                key={soundboard.id + soundboard.name}
                value={soundboard.id}
              >
                {soundboard.name}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonButton onClick={openModal}>
            <IonIcon slot="icon-only" icon={settingsOutline} />
          </IonButton>
        </IonItem>
      </IonButtons>
    );
  };

  const onLayoutChange = debounce((layouts: Layout[]) => {
    console.log('layoutchange.');
    currentSoundboard?.items.forEach((item) => {
      const layout = layouts.find((layout) => layout.i === item.id);
      if (layout) {
        item.x = layout.x;
        item.y = layout.y;
        item.height = layout.h;
        item.width = layout.w;
      }
    });
    saveSoundboard(collection, currentSoundboard);
  }, 1000);

  return currentSoundboard ? (
    <IonPage>
      <SoundboardSettings
        cancel={closeModal}
        isOpen={showModal}
        soundboard={currentSoundboard}
        update={updateSettings}
      />
      <Header name="Soundboard" extraHeader={renderSelect()} />
      {!loading ? (
        <div className="soundboard-container" ref={ref}>
          <Grid
            save={onLayoutChange}
            soundboard={currentSoundboard}
            height={height}
            width={width}
          />
        </div>
      ) : null}
      <IonFooter>
        <IonToolbar>
          <IonButtons></IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  ) : null;
};

export default SoundboardPage;

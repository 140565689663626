export const SOUND_CLIP_DIRECTORY = 'sound-clips';
export interface SoundClip {
  /**
   * Id of the sound clip.
   */
  id: string;
  /**
   * Name of the sound clip.
   */
  name: string;
  /**
   * User defined labels.
   */
  labels: string[];
  /**
   * Users tagged to this sound clip.
   */
  users: ClipUser[];
  /**
   * meta data used for searching on firebase.
   */
  searchMeta: string[];
  /**
   * The object name in storage.
   */
  objectName?: string;
  /**
   * The bucket where it is stored.
   */
  objectBucket?: string;
  /**
   * The user's id who uploaded the clip
   */
  userId: string;
  /* The displayName who uploaded the clip.
   */
  displayName: string;
  /**
   * Users who have access to this clip
   */
  sharedUser: string[];
  /**
   * Shared with friends.
   */
  shareFriends: boolean;
  /**
   * Shared with everyone.
   */
  shredEveryone: boolean;
  /**
   * When the sound was created.
   */
  created: Date;
  /**
   * When the sound was last updated.
   */
  updated: Date;
  /**
   * The default volume of the sound clip.
   */
  volume: number;
  /**
   * Average rating.
   */
  avgRating: number;
  /**
   * Number of ratings.
   */
  numRatings: number;
}

export interface ClipUser {
  id: string;
  name: string;
}

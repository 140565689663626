import { IonButton, IonIcon } from '@ionic/react';
import { star, starHalfOutline, starOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { RatingFillState, RatingProps } from './types';
import './style.css';
const calculateFills = (rating: number) => {
  const floor = Math.floor(rating);
  const fillState = [];
  for (let i = 0; i < 5; i++) {
    if (!rating) {
      fillState.push(RatingFillState.EMPTY);
    } else if (floor > i) {
      fillState.push(RatingFillState.FULL);
    } else if (rating > i) {
      fillState.push(RatingFillState.HALF);
    } else {
      fillState.push(RatingFillState.EMPTY);
    }
  }
  return fillState;
};

const calculateRating = (
  event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
  index: number
) => {
  const width = event.currentTarget.clientWidth;
  const bounds = event.currentTarget.getBoundingClientRect();
  const mouseX = event.clientX - bounds.left;
  let rating = index + 1;
  if (mouseX < width / 2) {
    rating = index + 0.5;
  }
  return rating;
};

export const Rating: React.FC<RatingProps> = ({
  rating,
  onRatingChange,
  disabled,
  className,
}) => {
  const [currentRating, setCurrentRating] = useState<RatingFillState[]>([]);

  useEffect(() => {
    setCurrentRating(calculateFills(rating ?? 0));
  }, [rating]);

  const onMouseEnter = (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    index: number
  ) => {
    const hoverRating = calculateRating(event, index);
    setCurrentRating(calculateFills(hoverRating));
  };

  const onMouseLeave = () => {
    setCurrentRating(calculateFills(rating ?? 0));
  };

  const onClick = (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    index: number
  ) => {
    const clickRating = calculateRating(event, index);
    if (onRatingChange) {
      onRatingChange(clickRating);
    }
  };
  const classContainerName = className ?? '';
  return (
    <div className={`${classContainerName} rating-container`}>
      {currentRating.map((state, index) => {
        const icon =
          state === RatingFillState.EMPTY
            ? starOutline
            : state === RatingFillState.FULL
            ? star
            : starHalfOutline;
        if (disabled) {
          return (
            <IonIcon
              key={index}
              className="rating-icon"
              slot="icon-only"
              icon={icon}
            />
          );
        }
        return (
          <IonButton
            size="large"
            fill="clear"
            key={index}
            onMouseMove={(e) => onMouseEnter(e, index)}
            onMouseEnter={(e) => onMouseEnter(e, index)}
            onMouseLeave={onMouseLeave}
            onClick={(e) => onClick(e, index)}
          >
            <IonIcon className="rating-icon" slot="icon-only" icon={icon} />
          </IonButton>
        );
      })}
    </div>
  );
};

export default Rating;

import {
  appsOutline,
  appsSharp,
  homeOutline,
  homeSharp,
  logInOutline,
  logInSharp,
  musicalNoteOutline,
  musicalNoteSharp,
  peopleOutline,
  peopleSharp,
  personOutline,
  personSharp,
} from 'ionicons/icons';
import { AppPage } from './types';

export const commonAppPages: AppPage[] = [
  {
    title: 'Home',
    url: '/page/Home',
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: 'Sound Clips',
    url: '/page/SoundClips',
    iosIcon: musicalNoteOutline,
    mdIcon: musicalNoteSharp,
  },
];

export const loggedOutAppPages: AppPage[] = [
  {
    title: 'Login',
    url: '/page/Login',
    iosIcon: logInOutline,
    mdIcon: logInSharp,
  },
];

export const userAppPages: AppPage[] = [
  {
    title: 'Profile',
    url: '/page/Profile',
    iosIcon: personOutline,
    mdIcon: personSharp,
  },
  {
    title: 'Friends',
    url: '/page/Friends',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
];

export const loggedInAppPages: AppPage[] = [
  {
    title: 'Soundboard',
    url: '/page/Soundboard',
    iosIcon: appsOutline,
    mdIcon: appsSharp,
  },
];

import {
  SoundClip,
  SoundClipRating,
  SOUND_CLIP_COLLECTION,
  SOUND_CLIP_RATINGS_COLLECTION,
} from '@cloud-soundboard/shared';
import {
  collection,
  doc,
  DocumentData,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { firestoreApp, PartialBy } from '../firebase';

const soundClipConverter = {
  toFirestore(soundClip: SoundClip): DocumentData {
    Object.keys(soundClip).forEach((key) => {
      if (soundClip[key] === undefined) {
        delete soundClip[key];
      }
    });
    return soundClip;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SoundClip {
    const data = snapshot.data(options);
    return data as SoundClip;
  },
};

const soundClipRatingConverter = {
  toFirestore(soundClip: SoundClipRating): DocumentData {
    Object.keys(soundClip).forEach((key) => {
      if (soundClip[key] === undefined) {
        delete soundClip[key];
      }
    });
    return soundClip;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SoundClipRating {
    const data = snapshot.data(options);
    return data as SoundClipRating;
  },
};

export function getSoundClipCollection() {
  return collection(firestoreApp, `${SOUND_CLIP_COLLECTION}`).withConverter(
    soundClipConverter
  );
}

export function getSoundClipRatingCollection(soundClipId: string) {
  return collection(
    firestoreApp,
    `${SOUND_CLIP_COLLECTION}`,
    soundClipId,
    SOUND_CLIP_RATINGS_COLLECTION
  ).withConverter(soundClipRatingConverter);
}

export type NewSoundClip = PartialBy<SoundClip, 'id'>;

/**
 * Save a sound clip. Generates an id if one is not available.
 * @param soundClip to save.
 * @returns the result of the save.
 */
export const saveSoundClip = (soundClip: NewSoundClip) => {
  const collection = getSoundClipCollection();
  const toSave = { id: soundClip.id ?? nanoid(), ...soundClip };
  return setDoc<SoundClip>(doc<SoundClip>(collection, toSave.id), toSave);
};

export type NewSoundClipRating = PartialBy<SoundClipRating, 'id'>;

export const saveSoundClipRating = (soundClipRating: NewSoundClipRating) => {
  const collection = getSoundClipRatingCollection(soundClipRating.soundClipId);
  const toSave = { ...soundClipRating, id: soundClipRating.id ?? nanoid() };
  return setDoc<SoundClipRating>(
    doc<SoundClipRating>(collection, toSave.id),
    toSave
  );
};

import {
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonItemDivider,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { IonButton, IonContent, IonModal, IonPage } from '@ionic/react';
import { SoundboardSettingsParams } from './types';
import { useForm } from 'react-hook-form';
import { Soundboard } from '@cloud-soundboard/shared';

const SoundboardSettings: React.FC<SoundboardSettingsParams> = ({
  soundboard,
  isOpen,
  cancel,
  update,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: soundboard,
  });

  const onSubmit = (data: Soundboard) => update(data);

  return (
    <IonModal isOpen={isOpen} cssClass="my-custom-class">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Update Soundboard settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <IonList>
          <IonItem>
            <IonTitle>Name</IonTitle>
            <IonInput
              {...register('name', { required: true })}
              placeholder="Name"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonTitle>Padding</IonTitle>
            <IonInput
              {...register('padding', { required: true })}
              placeholder="Size"
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonTitle>Size</IonTitle>
            <IonInput
              {...register('itemSize', { required: true })}
              placeholder="Size"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonTitle>Rows</IonTitle>
            <IonInput
              {...register('rows', { required: true })}
              placeholder="Rows"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonTitle>Columns</IonTitle>
            <IonInput
              {...register('cols', { required: true })}
              placeholder="Columns"
            ></IonInput>
          </IonItem>
        </IonList>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={cancel}>Cancel</IonButton>
              <IonButton type="submit" color="primary">
                Update
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </form>
    </IonModal>
  );
};

export default SoundboardSettings;

import { SearchbarChangeEventDetail } from '@ionic/core';
import { IonSearchbar } from '@ionic/react';
import { connectSearchBox } from 'react-instantsearch-core';
import { IonInstantSearchBarProps } from './types';

const IonInstantSearchBar: React.FC<IonInstantSearchBarProps> = (props) => {
  const { refine, currentRefinement } = props;
  if (!refine) {
    return null;
  }

  const onChange = (event: CustomEvent<SearchbarChangeEventDetail>) => {
    refine(event.detail.value);
  };

  return (
    <IonSearchbar
      onIonCancel={() => refine('')}
      onIonClear={() => refine('')}
      value={currentRefinement}
      onIonChange={onChange}
      {...props}
    />
  );
};

export default connectSearchBox(IonInstantSearchBar);

import {
  IonButton,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
} from "@ionic/react";
import { FilterPopoverProps } from "./types";
import "./style.css";
import SoundRating from "components/SoundRating/SoundRating";
import { connectRange } from "react-instantsearch-core";
import { useState } from "react";
import Accordion from "components/Accordion/Accordion";

const FilterPopover: React.FC<FilterPopoverProps> = ({
  onDidDismiss,
  event,
  ratingState,
}) => {
  const VirtualRange = connectRange(() => null);

  return (
    <>
      <VirtualRange defaultRefinement={ratingState} attribute="avgRating" />
      <IonPopover
        cssClass="filter-popover"
        isOpen={!!event}
        event={event}
        onDidDismiss={onDidDismiss}
      >
        <IonList className="filter-popover-container">
          <IonListHeader lines="full">
            <IonLabel></IonLabel>
            <IonButton onClick={onDidDismiss} fill="clear">
              Close
            </IonButton>
          </IonListHeader>
          <Accordion title="Rating">
            <SoundRating
              defaultRefinement={ratingState}
              attribute="avgRating"
              min={0}
              max={5}
            />
          </Accordion>
        </IonList>
      </IonPopover>
    </>
  );
};

export default FilterPopover;

export interface RatingProps {
  disabled?: boolean;
  rating?: number;
  className?: string;
  onRatingChange?: (rating: number) => void;
}

export enum RatingFillState {
  EMPTY,
  HALF,
  FULL,
}

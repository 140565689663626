import { GridProps } from './types';
import GridLayout, { Layout } from 'react-grid-layout';
import './style.css';
import GridItem from './GridItem';
import { memo, useEffect, useMemo, useState } from 'react';
import { SoundboardItem } from '@cloud-soundboard/shared';
import { TEMP_ITEM } from '../../model/Soundboard';
import { isEqual } from 'lodash';

const DEFAULT_MARGIN = 10;

const Grid: React.FC<GridProps> = ({ soundboard, width, save }) => {
  const size = parseInt(`${soundboard.itemSize}`);
  const padding = parseInt(`${soundboard.padding}`);
  const cols = parseInt(`${soundboard.cols}`);
  const [gridItems, setGridItems] = useState<SoundboardItem[]>([]);

  useEffect(() => {
    const items = [...(soundboard.items ?? [])];
    // for (let i = 0; i < soundboard.cols; i++) {
    //   for (let j = 0; j < soundboard.rows; j++) {
    //     // could not find item push it.
    //     if (!items.find((item) => item.x === i && item.y === j)) {
    //       items.push({
    //         x: i,
    //         y: j,
    //         id: `${TEMP_ITEM}-${i}-${j}`,
    //         height: 1,
    //         width: 1,
    //       });
    //     }
    //   }
    // }
    // soundboard.items = items;
    setGridItems(items);
  }, [size, soundboard.cols, soundboard.rows, soundboard]);

  const onLayoutChange = (layout: Layout[]) => {
    save(layout);
  };

  const children = useMemo(() => {
    return gridItems.map((item) => (
      <div
        className="grid-item-wrapper"
        key={item.id}
        data-grid={{
          height: size,
          width: size,
          x: item.x,
          y: item.y,
          w: item.width,
          h: item.height,
        }}
      >
        <GridItem item={item} />
      </div>
    ));
  }, [gridItems, size]);

  return (
    <GridLayout
      margin={[DEFAULT_MARGIN, DEFAULT_MARGIN]}
      isDraggable={true}
      isResizable={true}
      resizeHandles={['se']}
      onLayoutChange={onLayoutChange}
      className="layout"
      rowHeight={size}
      compactType={null}
      cols={cols}
      containerPadding={[padding, padding]}
      width={width}
    >
      {children}
    </GridLayout>
  );
};

export default memo(Grid, (prev, next) => {
  return isEqual(prev, next);
});

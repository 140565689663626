import { SoundClip, SOUND_CLIP_DIRECTORY } from '@cloud-soundboard/shared';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { ConfigureSoundClipProps } from './types';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { NewSoundClip, saveSoundClip } from '../../model/Soundclip';
import { useAuthState } from 'react-firehooks/auth';
import { getAuth } from 'firebase/auth';
import { nanoid } from 'nanoid';

const ConfigureSoundClip: React.FC<ConfigureSoundClipProps> = ({
  soundClip,
  isOpen,
  close,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const [user] = useAuthState(getAuth());
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: soundClip,
  });

  const onDrop = useCallback(
    (acceptedFiles) => setFile(acceptedFiles[0]),
    [setFile]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'audio/*',
    onDrop,
    multiple: false,
    disabled: !!soundClip,
    maxSize: 20_000_000,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  ) as any;

  const onSubmit = async (data: NewSoundClip) => {
    if (!user) {
      throw new Error('Only users can save new clips.');
    }
    // upload the file if applicable.
    if (file) {
      if (!data.id) {
        data.id = nanoid();
      }
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `${SOUND_CLIP_DIRECTORY}/${user.uid}/${data.id}`
      );
      const { ref: uploadRef } = await uploadBytes(storageRef, file);
      data.objectBucket = uploadRef.bucket;
      data.objectName = uploadRef.fullPath;
    }
    data.userId = user.uid;
    data.displayName = user.displayName ?? '';
    console.log(data);
    saveSoundClip(data);
    close();
  };

  const submitEnabled = !Object.values(errors).length && (soundClip || file);

  const acceptedFile = acceptedFiles[0]
    ? `${acceptedFiles[0]?.name} - ${humanFileSize(acceptedFiles[0].size)}`
    : null;
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {soundClip ? 'Update' : 'Add'} Sound Clip
            {soundClip ? ` ${soundClip.name}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <IonList>
          <IonItem>
            <IonTitle>Name</IonTitle>
            <IonInput
              {...register('name', { required: true })}
              placeholder="Name"
            ></IonInput>
          </IonItem>

          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>

          <IonItem>
            <IonTitle>File</IonTitle>
            <IonLabel>{acceptedFile ? acceptedFile : ''}</IonLabel>
          </IonItem>

          <IonItem>
            <IonTitle>Share</IonTitle>
            <IonCheckbox {...register('shredEveryone')} />
            <IonLabel style={{ paddingLeft: 10 }}>Share with Everyone</IonLabel>
          </IonItem>

          <IonItem>
            <IonTitle>Default Volume</IonTitle>
            <IonInput
              type="number"
              {...register('volume', { valueAsNumber: true, required: true })}
              placeholder="Default Volume"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonTitle>Labels</IonTitle>
            <IonInput {...register('labels')} placeholder="Labels"></IonInput>
          </IonItem>
        </IonList>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={close}>Cancel</IonButton>
              <IonButton
                disabled={!submitEnabled}
                type="submit"
                color="primary"
              >
                {soundClip ? 'Update' : 'Create'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </form>
    </IonModal>
  );
};

const baseStyle = {
  margin: '10px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

export default ConfigureSoundClip;

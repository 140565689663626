import { IonIcon, isPlatform } from '@ionic/react';
import { motion } from 'framer-motion';
import { settingsOutline } from 'ionicons/icons';
import { useState } from 'react';
import './gridItemStyle.css';
import { GridItemProps } from './types';

const GridItem: React.FC<GridItemProps> = ({ item }) => {
  const mobile = isPlatform('mobile');
  if (item.soundClip) {
    return null;
  }
  if (mobile) {
    return <EmptyMobileGridItem item={item} />;
  }
  return <EmptyGridItem item={item} />;
};

export default GridItem;

const EmptyMobileGridItem: React.FC<GridItemProps> = ({ item }) => {
  return (
    <motion.div className="grid-item">
      <motion.button
        style={{
          borderRadius: 20,
          height: '100%',
          width: '100%',
        }}
        whileTap={{ scale: 0.9 }}
      >
        <IonIcon icon={settingsOutline}></IonIcon>
      </motion.button>
    </motion.div>
  );
};

const EmptyGridItem: React.FC<GridItemProps> = ({ item }) => {
  const [hover, setHover] = useState(false);

  return (
    <motion.div
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      className="grid-item"
    >
      {hover ? (
        <motion.button
          style={{
            borderRadius: 20,
            height: 10,
            width: 10,
            overflow: 'hidden',
          }}
          whileTap={{ scale: 0.9 }}
          animate={{
            height: '100%',
            width: '100%',
            transition: { duration: 0.3 },
          }}
        >
          <IonIcon icon={settingsOutline}></IonIcon>
        </motion.button>
      ) : null}
    </motion.div>
  );
};

import { IonActionSheet } from '@ionic/react';
import RateSoundClip from 'components/RateSoundClip/RateSoundClip';
import {
  closeSharp,
  heartSharp,
  playSharp,
  shareSharp,
  starHalfSharp,
} from 'ionicons/icons';
import { SoundClipActionSheetProps } from './types';
import { useState } from 'react';

const SoundClipActionSheet: React.FC<SoundClipActionSheetProps> = ({
  soundClip,
  isOpen,
  onDidDismiss,
}) => {
  const [ratingOpen, setRatingOpen] = useState(false);

  const didDismiss = () => {
    if (!ratingOpen) {
      onDidDismiss();
    }
  };

  return (
    <>
      <RateSoundClip
        open={ratingOpen}
        soundClip={soundClip}
        onDidDismiss={onDidDismiss}
      />
      <IonActionSheet
        header={`${soundClip.name}`}
        isOpen={isOpen}
        onDidDismiss={didDismiss}
        buttons={[
          {
            text: 'Play',
            icon: playSharp,
            handler: () => {
              console.log('Delete clicked');
            },
          },
          {
            text: 'Share',
            icon: shareSharp,
            handler: () => {
              console.log('Share clicked');
            },
          },
          {
            text: 'Rate',
            icon: starHalfSharp,
            handler: () => {
              setRatingOpen(true);
            },
          },
          {
            text: 'Favorite',
            icon: heartSharp,
            handler: () => {
              console.log('Favorite clicked');
            },
          },
          {
            text: 'Close',
            icon: closeSharp,
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            },
          },
        ]}
      ></IonActionSheet>
    </>
  );
};

export default SoundClipActionSheet;

import { IonContent, IonPage } from '@ionic/react';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useHistory } from 'react-router-dom';
import { firebaseAuth } from '../../firebase';
import Header from 'components/Header/Header';

const Login: React.FC = () => {
  const history = useHistory();
  const signInSuccessWithAuthResult = (
    _authResult: any,
    _redirectUrl?: string
  ): boolean => {
    history.push('Home');

    return false;
  };
  const signInFailure = (
    _error: firebaseui.auth.AuthUIError
  ): Promise<void> | void => {
    // TODO
  };
  const uiShown = (): void => {
    // TODO
  };

  const uiConfig: firebaseui.auth.Config = {
    callbacks: {
      signInFailure,
      signInSuccessWithAuthResult,
      uiShown,
    },
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      { provider: EmailAuthProvider.PROVIDER_ID, requireDisplayName: true },
    ],
  };

  return (
    <IonPage>
      <Header name="Login" />

      <IonContent fullscreen>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
      </IonContent>
    </IonPage>
  );
};

export default Login;

import { IonIcon, IonLabel } from "@ionic/react";
import { star, starOutline } from "ionicons/icons";
import { connectRange } from "react-instantsearch-core";
import { SoundRatingProps } from "./types";
import "./style.css";
import { motion } from "framer-motion";

const SoundRating: React.FC<SoundRatingProps> = (props) => {
  const { refine, currentRefinement, className } = props;
  if (!refine) {
    return null;
  }

  const refineRating = (rating: number) => {
    if (currentRefinement?.min === rating) {
      refine({
        min: undefined,
      });
    } else {
      refine({
        min: rating,
      });
    }
  };

  const renderStarGroup = (rating: number) => {
    const buttons = [];
    for (let i = 0; i < 5; i++) {
      const icon = i < rating ? star : starOutline;
      buttons.push(
        <IonIcon
          className="sound-rating-icon"
          key={i}
          size="small"
          icon={icon}
        />
      );
    }
    const active = currentRefinement?.min === rating ? "active" : "";

    return (
      <motion.div
        whileTap={{ scale: 0.95 }}
        className={`${active} sound-rating-group`}
        onClick={() => refineRating(rating)}
      >
        {buttons}
        <span className="and-up">&amp; Up</span>
      </motion.div>
    );
  };

  return (
    <div className={`${className ?? ""} sound-rating-container`}>
      {renderStarGroup(1)}
      {renderStarGroup(2)}
      {renderStarGroup(3)}
      {renderStarGroup(4)}
      {renderStarGroup(5)}
    </div>
  );
};

export default connectRange(SoundRating);

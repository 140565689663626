import { SoundClipRating } from '@cloud-soundboard/shared';
import {
  getDocs,
  query,
  QueryDocumentSnapshot,
  where,
} from '@firebase/firestore';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { firebaseAuth } from 'model/config';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import Rating from 'components/Rating/Rating';
import {
  getSoundClipRatingCollection,
  saveSoundClipRating,
} from '../../model/Soundclip';
import './style.css';
import { RateSoundClipProps } from './types';

export const RateSoundClip: React.FC<RateSoundClipProps> = ({
  open,
  soundClip,
  onDidDismiss,
}) => {
  const [rating, setRating] = useState<number>(0);
  const [userReview, setUserReview] =
    useState<QueryDocumentSnapshot<SoundClipRating> | null>(null);
  const [user] = useAuthState(firebaseAuth);

  useEffect(() => {
    async function loadUserReview() {
      if (!user) {
        return null;
      }
      const collection = getSoundClipRatingCollection(soundClip.id);
      const { docs } = await getDocs(
        query(collection, where('userId', '==', user.uid))
      );

      setUserReview(docs[0]);
      if (docs[0]?.data()) {
        setRating(docs[0].data().rating);
      }
    }
    loadUserReview();
  }, [soundClip.id, user]);

  const saveRating = () => {
    if (!user) {
      console.error('Only signed in users can save ratings.');
      return;
    }
    saveSoundClipRating({
      rating,
      soundClipId: soundClip.id,
      userId: user.uid,
      id: userReview?.data().id,
    });
    onDidDismiss();
  };

  // TODO load users rating.

  return (
    <IonModal cssClass="rating-modal" onDidDismiss={onDidDismiss} isOpen={open}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Rate - {soundClip.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p className="rate-text">Rate the quality of the sound clip.</p>
        <div className="rating-container">
          <Rating rating={rating} onRatingChange={setRating} />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onDidDismiss}>Cancel</IonButton>
            <IonButton onClick={saveRating}>Save</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default RateSoundClip;

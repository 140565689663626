import { getAnalytics } from 'firebase/analytics';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCYHMVnV4sLukSulRxrZqf8WjThTht2Epg',
  authDomain: 'awesome-discord-bots.firebaseapp.com',
  projectId: 'awesome-discord-bots',
  storageBucket: 'awesome-discord-bots.appspot.com',
  messagingSenderId: '677236676644',
  appId: '1:677236676644:web:c4436187cc1874a903e0b1',
  measurementId: 'G-W92W181PT9',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firestoreApp = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

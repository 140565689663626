import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = ({ name, extraHeader }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>{name}</IonTitle>
        {extraHeader ?? null}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;

import { SoundClip } from '@cloud-soundboard/shared';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import Rating from 'components/Rating/Rating';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { connectInfiniteHits, Highlight } from 'react-instantsearch-dom';
import SoundClipActionSheet from './SoundClipActionSheet';
import { SoundClipItemProps, SoundClipItemsProps } from './types';

const SoundClipItem: React.FC<SoundClipItemProps> = ({
  soundClip,
  onSoundClipClick,
}) => {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <IonCard onClick={() => onSoundClipClick(soundClip)} class="card">
        <IonCardHeader>
          <IonCardTitle>
            <Highlight attribute="name" hit={soundClip} />
          </IonCardTitle>
          <IonCardSubtitle>
            <Rating
              className="item-rating"
              disabled
              rating={soundClip.avgRating}
            />
          </IonCardSubtitle>
          <IonCardContent>
            <Highlight attribute="description" hit={soundClip} />
          </IonCardContent>

          <IonCardSubtitle>
            <Highlight attribute="displayName" hit={soundClip} />
          </IonCardSubtitle>
        </IonCardHeader>
      </IonCard>
    </motion.div>
  );
};

const SoundClipItems: React.FC<SoundClipItemsProps> = ({
  hasMore,
  hasPrevious,
  hits,
  refineNext,
  refinePrevious,
}) => {
  const [currentSoundClip, setCurrentSoundClip] = useState<SoundClip | null>();

  return (
    <IonGrid className="sound-clips-grid">
      {currentSoundClip ? (
        <SoundClipActionSheet
          soundClip={currentSoundClip}
          isOpen={true}
          onDidDismiss={() => setCurrentSoundClip(null)}
        />
      ) : null}
      <IonRow>
        <AnimatePresence presenceAffectsLayout={true}>
          {hits.map((hit) => (
            <IonCol size-md="3" size="6" size-sm key={hit.id}>
              <SoundClipItem
                onSoundClipClick={setCurrentSoundClip}
                soundClip={hit}
              />
            </IonCol>
          ))}
        </AnimatePresence>
      </IonRow>
    </IonGrid>
  );
};

export default connectInfiniteHits(SoundClipItems);
